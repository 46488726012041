import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Card, CardBody, CardTitle, CardText } from "reactstrap";
import Styled from "styled-components";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { some } from "lodash";

import Redirect from "../components/Redirect";
import Text from "../components/Text";
import Popup from "../components/Popup";
import Conditional from "../components/Conditional";
import Iframe from "../components/Iframe";
import Image from "../components/Common/Image";
import Emitter from "helpers/emitter_helper";

import { getWizard } from "../store/actions";

class Step extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      option: null,
      step: props.step,
      footer: [],
      styles: this.props.css ? this.props.css : '',
      isIframe: false
    };
  }

  componentDidMount() {
    if (this.props.wizard) {
      this.onWizardSuccess(true);
    }

    Emitter.on('IS_IFRAME', (value) => {
      if (value && !this.state.isIframe) {
        this.setState({ isIframe: true });
      }
    });
  }

  componentWillUnmount() {
    Emitter.off('IS_IFRAME');
  }

  sortOptions(options) {
    options.sort((a, b) => { return a.order - b.order });

    options.forEach((o) => {
      if (o.options) {
        this.sortOptions(o.options);
      }
    });

    return options;
  }

  onWizardSuccess(success) {
    if (success) {
      let options = this.props.wizard.options;
      options = this.sortOptions(options);

      if (this.state.step.secured) {
        if (!sessionStorage.getItem('authenticated')) {
          const url = '/' + options[0].clientId;

          window.top.location = url;
        }
      }

      this.setState({
        steps: options,
        footer: this.props.wizard.footer || [],
        styles: this.props.css
      });
    }
  }

  renderComponent(step) {
    switch (step.type) {
      case 'redirect':
        return <Redirect step={step} wizard={this.props.wizard}></Redirect>;
      case 'text':
        return <Text step={step} wizard={this.props.wizard}></Text>;
      case 'html':
        return <Popup step={step} wizard={this.props.wizard}></Popup>;
      case 'conditional':
        return <Conditional step={step} wizard={this.props.wizard}></Conditional>;
      case 'iframe':
        return <Iframe step={step} wizard={this.props.wizard}></Iframe>;
    }
  }

  setOption(option) {
    if (option.type != 'conditional') {
      let steps = this.state.step.options.map((step) => {
        if (step.options) {
          step.options.sort((a, b) => { return a.order - b.order });
        }
        
        if (option.id == step.id) {
          step.selectedClass = 'selected';
        } else {
          step.selectedClass = '';
        }
  
        return step;
      });
  
      this.setState({ steps, option }, () => {
        if (this.hasAnySubSteps()) {
          this.nextStep();
        }
      });
    }
  }

  nextStep() {
    let steps = this.state.step.options.map((step) => {
      step.selectedClass = '';

      return step;
    });

    this.setState({ steps });

    this.props.history.push('/' + this.props.id + '/' + this.state.option.id + this.props.location.search, { from: location.pathname });
  }

  hasAnySubSteps() {
    return some(this.state.step.options, (step) => {
      return step.options && step.options.length;
    });
  }

  goBack() {
    if (this.state.isIframe) {
      if (this.props.history.location.state?.from) {
        window.top.location = this.props.history.location.state.from;
      } else {
        window.top.location = '/' + this.props.id;
      }
    } else {
      this.props.history.goBack();
    }
  }

  Wrapper = () => Styled.div`
    ${this.state.styles}
  `;
  render() {
    const Wrapper = this.Wrapper();

    return (
      this.props.wizard ? (
      <Wrapper>
        <Container className="mb-3 wizard-container">
          <Row className="mt-3 mb-3">
            <Col>
              <div className="text-center cursor-pointer">
                <Image image={this.props.wizard.logo} id={this.props.match.params.id} onClick={() => { this.props.history.push('/' + this.props.id) }} />
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center text-center">
            <Col md={12} onClick={() => this.goBack()}>
              <div className="back-arrow">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title"
                  aria-describedby="desc" role="img">
                  <path data-name="layer2"
                    fill="none" stroke={this.props.wizard.color} strokeMiterlimit="10" strokeWidth="2" d="M14 31.998h36"
                    strokeLinejoin="round" strokeLinecap="round"></path>
                  <path data-name="layer1" fill="none" stroke={this.props.wizard.color} strokeMiterlimit="10"
                    strokeWidth="2" d="M28 18L14 32l14 14" strokeLinejoin="round" strokeLinecap="round"></path>
                </svg>
              </div>
            </Col>
          </Row>
          <Row className="text-center mb-3">
            <Col>
              <h1>{this.state.step.title}</h1>
              { this.state.step.subtitle ?
                <h5>{this.state.step.subtitle}</h5>
              : undefined }
            </Col>
          </Row>
          <Row className="justify-content-center">
            { this.state.step.options?.length ? (
              <>
                {this.state.step.options.map((step, key) => {
                  return (
                    <Col md={step.type == 'conditional' ? 6 : step.type == 'iframe' ? 12 : 3} xs={12} key={step.id} className="mb-3">
                      {step.options && step.options.length ? (
                      this.props.wizard.mode == 'icon' ?
                      <Card className={`icon-card h-100 cursor-pointer ${step.selectedClass}`} style={step.styles} onClick={() => { this.setOption(step) }}>
                        <CardBody className="d-flex align-items-center">
                          <Row className="align-items-center">
                            <Col>
                              <Image image={step.resolvedImageUrl} id={this.props.match.params.id} isCard={true} />
                            </Col>
                            {step.title ?
                            <Col>
                              <CardTitle className="h4 mt-0">{step.title}</CardTitle>
                            </Col>
                            : undefined }
                          </Row>
                        </CardBody>
                      </Card>
                      :
                      <Card className={`h-100 cursor-pointer ${step.selectedClass}`} onClick={() => { this.setOption(step) }}>
                        <Image image={step.resolvedImageUrl} id={this.props.match.params.id} isCard={true} />
                        <CardBody>
                          <CardTitle className="h4 mt-0">{step.title}</CardTitle>
                          <CardText dangerouslySetInnerHTML={{ __html: step.content ? step.content.replace(/(<? *script)/gi, 'illegalscript') : '' }}>
                          </CardText>
                        </CardBody>
                      </Card>
                      ) : this.renderComponent(step) }
                    </Col>
                  );
                })}
                {/* { this.hasAnySubSteps() ? (
                <Row className="justify-content-center text-center mt-3 actions">
                  <Col md={3} xs={12}>
                    <button disabled={!this.state.option} onClick={() => this.nextStep()} className="btn">Continuar</button>
                  </Col>
                </Row>
                ) : undefined } */}
              </>
              ) : 
              this.renderComponent(this.state.step)
            }
          </Row>
          {this.state.footer.length ?
          <footer className="main-footer mx-3">
            <ul className="list-unstyled list-inline text-center">
            {this.state.footer.map((link, key) => {
              return (
                <li className="list-inline-item" key={key}>
                  <a href={link.url} target="_blank" className="mx-2">{link.text}</a>
                </li>
              );
            })}
            </ul>
          </footer>
          : undefined}
        </Container>
      </Wrapper>
      ) : null
    );
  }
}

Step.propTypes = {
  wizard: PropTypes.object,
  css: PropTypes.string,
  error: PropTypes.any,
  history: PropTypes.object,
};

const mapStateToProps = state => {
  const { loading, id, wizard, css, error } = state.Wizard;
  return {
    loading, id, wizard, css, error
  };
};

export default connect(mapStateToProps, {
  getWizard
})(withRouter(Step));
